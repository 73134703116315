import { useEffect } from "react";
import "./index.css";
const OkxUid = (): JSX.Element => {
  useEffect(() => {
    document.title = "Find My OKX's UID";
    return () => {
      document.title = "witcoin";
    };
  }, []);
  return (
    <>
      <div id="pcontainer">
        <div className="section">
          <p>
            1. Open the OKX app and log into your account. Select the first tab
            on the bottom navigation bar, then click on the dotted square icon
            in the upper left corner.
          </p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx_uid/1.png").default}
              alt=""
            />
          </p>
          <p>
            2. Open up your profile page by clicking the little arrow on the top
            right of the page.
          </p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx_uid/2.png").default}
              alt=""
            />
          </p>
          <p>
            3. You can see the UID number under your profile picture. Click on
            the little square icon next to it to copy it to your clipboard.
          </p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx_uid/3.png").default}
              alt=""
            />
          </p>
          <p>
            4. Now go back to Witcoin to paste the UID number you just copied
            and make a withdrawal request!
          </p>
        </div>
      </div>
    </>
  );
};

export default OkxUid;
