import { lazy } from "react";
import { AppRoutes } from "../interfaces";
import { Navigate } from "react-router-dom";
import OkxUid from "../pages/okx_uid";

export const Home = lazy(() => import("../pages/Home"));
export const HttpPage = lazy(() => import("../pages/Request"));
export const Guidelines = lazy(() => import("../pages/guidelines"));
export const Safety = lazy(() => import("../pages/safety"));
export const ParentsGuide = lazy(() => import("../pages/parents_guide"));
export const LegalNotice = lazy(() => import("../pages/legal-notice"));
export const PrivacyPolicy = lazy(() => import("../pages/privacy_policy"));
export const WithdrawOkx = lazy(() => import("../pages/withdraw-okx"));
export const WithdrawDoge = lazy(() => import("../pages/withdraw-doge"));
export const WithdrawOkxKr = lazy(() => import("../pages/withdraw-okx-kr"));
export const WithdrawBingx = lazy(() => import("../pages/withdraw-bingx"));
export const WithdrawLBank = lazy(() => import("../pages/withdraw-lbank"));
export const Wallet = lazy(() => import("../pages/wallet"));
export const Terms = lazy(() => import("../pages/terms"));
export const BingX = lazy(() => import("../pages/bingx_uid"));
export const LBankUid = lazy(() => import("../pages/lbank_uid"));


export default [
  {
    path: "/",
    name: "home",
    component: <Home />,
  },
  {
    path: "/http",
    name: "http",
    component: <HttpPage />,
  },
  {
    path: "/guidelines",
    name: "guidelines",
    component: <Guidelines />,
  },
  {
    path: "/safety",
    name: "safety",
    component: <Safety />,
  },
  {
    path: "/parents_guide",
    name: "parents_guide",
    component: <ParentsGuide />,
  },
  {
    path: "/legal-notice",
    name: "legal-notice",
    component: <LegalNotice />,
  },
  {
    path: "/privacy_policy",
    name: "privacy_policy",
    component: <PrivacyPolicy />,
  },
  {
    path: "/withdraw-okx",
    name: "withdraw-okx",
    component: <WithdrawOkx />,
  },
  {
    path: "/withdraw-bingx/:locales",
    name: "withdraw-bingx-locales",
    component: <WithdrawBingx />,
  },
  {
    path: "/withdraw-bingx",
    name: "withdraw-bingx",
    component: <WithdrawBingx />,
  },
  {
    path: "/withdraw-doge/:locales",
    name: "withdraw-doge-locales",
    component: <WithdrawDoge />,
  },
  {
    path: "/withdraw-doge",
    name: "withdraw-doge",
    component: <WithdrawDoge />,
  },

  {
    path: "/withdraw-okx-kr",
    name: "withdraw-okx-kr",
    component: <WithdrawOkxKr />,
  },
  {
    path: "/okx_uid",
    name: "okx-uid",
    component: <OkxUid />,
  },
  {
    path: "/wallet",
    name: "wallet",
    component: <Wallet />,
  },
  {
    path: "/terms",
    name: "terms",
    component: <Terms />,
  },
  {
    path: "*",
    name: "*",
    component: <Navigate to="/" replace={true} />,
  },
  {
    path: "/bingx_uid",
    name: "bingx_uid",
    component: <BingX />,
  },
  {
    path: "/lbank_uid",
    name: "lbank_uid",
    component: <LBankUid />,
  },
  {
    path: "/withdraw-lbank",
    name: "withdraw-lbank",
    component: <WithdrawLBank />,
  },
] as AppRoutes;
