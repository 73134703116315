import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";

import routes from "./config/routes";
import { Suspense } from "react";

function App() {
  return (
    <>
      <Router>
        <Suspense fallback>
          <Routes>
            {routes.map((route) => (
              <Route
                key={route.name}
                path={route.path}
                element={route.component}
              ></Route>
            ))}

            {/* <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/guidelines" element={<Guidelines />} />
          <Route path="/safety" element={<Safety />} />
          <Route path="/parents_guide" element={<ParentsGuide />} />
          <Route path="/legal-notice" element={<LegalNotice />} />
          <Route path="/withdraw-okx" element={<WithdrawOkx />} />
          <Route path="/withdraw-okx-kr" element={<WithdrawOkxKr />} />
          <Route path="/withdraw-bingx/:locales" element={<WithdrawBingx />} />
          <Route path="/withdraw-bingx" element={<WithdrawBingx />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/http" element={<HttpPage />} />
          <Route path="*" element={<Navigate to="/" replace={true} />} /> */}
          </Routes>
        </Suspense>

        <ToastContainer theme="dark" autoClose={3000} hideProgressBar />
      </Router>
    </>
  );
}

export default App;
